// import bgPattern from "./images/bg-pattern.png";
import "./App.css";
import { useMemo } from "react";

import Home from "./Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Attributes from "./components/Attributes";
import Roadmap from "./components/Roadmap";


import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 60000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#44B748",
    },
    secondary: {
      main: "#EB974A",
    },
    action: {
      disabledBackground: "#3d3d3d",
      disabled: "#b8b800",
    },
  },
  typography: {
    fontFamily: ['Single Day', 'sans-serif',].join(','),
    h1: {
      fontFamily: 'Rum Raisin',
      fontSize: "2rem",
      lineHeight: "2.2rem",
    },
  },
  overrides: {

    MuiButtonBase: {
      root: {
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        padding: '10px 16px',
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
      label: {
        fontSize: "1.5rem",
      },
      contained: {
        color: "#ffffff",
      },
      containedPrimary: {
        color: "#ffffff",
      }
    },
  },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <Home
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>

      <Attributes />
      <Roadmap />
      <Footer />

    </ThemeProvider>
  );
};

export default App;
