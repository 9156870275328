import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import tikiTitle from '../img/tiki-totems-title.png';
import heroImage from '../img/hero.png';
import blankSign from '../img/blank-sign.png';

const useStyles = makeStyles((theme => ({
  heading: {
    padding: "4rem 1rem 2rem 1rem",
  },
  headingImage: {
    width: "100%",
    maxWidth: "706px",
  },
  hero: {
    padding: "0rem 1rem",
  },
  heroImage: {
    width: "100%",
    maxWidth: "944px",
  },
  tikiIntro: {
    display: "flex",
    width: "75%",
    margin: "0 auto",
    maxWidth: "870px",
    minHeight: "320px",
    background: `url(${blankSign})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
  },
  tikiIntroText: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    [theme.breakpoints.down(480)]: {
      padding: "2rem 1.5rem",
      fontSize: "medium",
    },
    [theme.breakpoints.up(480)]: {
      padding: "2rem 4rem",
      fontSize: "large",
    },
    [theme.breakpoints.up(820)]: {
      padding: "3rem 6rem",
      fontSize: "x-large",
    },
  },
  reasonSection: {
    marginTop: "4rem",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
    rowGap: "2em",
    columnGap: "1em",
    width: "100%",
    position: "relative",
  },
  reasonBlock: {
    display: "block",
    width: "100%",
    maxWidth: "360px",
    overflow: "visible",
    padding: "1rem",
    background: "rgba(250, 199, 152, 0.65)",
    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.4)",
    border: "2px solid #844502",
    borderRadius: "3px",
    justifyContent: "center",
    textAlign: "left",
    [theme.breakpoints.down(480)]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up(480)]: {
      fontSize: "large",
    },
    [theme.breakpoints.up(820)]: {
      fontSize: "x-large",
    },
  },
  reasonHeader: {
    color: "#ffffff",
    textAlign: "center",
    textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
  },
  notificationTopBar: {
    position: "fixed",
    top: "0px",
    left: "0px",
    height: "40px",
    lineHeight: "40px",
    width: "100%",
    zIndex: 100,
    background: "#44B748",
    textAlign: "center",
    color: "#ffffff",
    fontFamily: "sans-serif",
    "& p": {
      padding: "0px",
      marginTop: "0px",
      marginBottom: "0px",
      borderRadius: "3px",
      fontWeight: "bold",
      textDecoration: "none",
    },
    "& a": {
      color: "#1f1f1f",
      textDecoration: "none",
    },

  },
})));
export default function Header() {
  const classes = useStyles();
  return <main>
    <div className={classes.notificationTopBar}>
      <p>Mint your Tiki Totem today! <a href="#minttikitotem">Click Here</a></p>
      {/* <p>Mint your Tiki Totem now! <a href="#minttikitotem">Click Here</a></p> */}
    </div>
    <div className={classes.heading}>
      <img className={classes.headingImage} src={tikiTitle} alt={"Tiki Totems"} />
    </div>
    <div className={classes.hero}>
      <img className={classes.heroImage} src={heroImage} alt={"Tiki Island"} />
    </div>
    <div className={classes.tikiIntro}>
      <p className={classes.tikiIntroText}>Chilling on the tropical islands of the metaverse are 8888 uniquely generated Tiki Totems. Each Tiki is complemented with its own distinctive attributes making every Tiki unique. However, perhaps some are more rare than others...</p>
    </div>
    <div className={classes.reasonSection}>
      <div className={classes.reasonBlock}>
        <h1 className={classes.reasonHeader}>Tiki Totems bring joy!</h1>
        <p>Respect and embrace your Tiki Totem and it will bring you much joy! For some of you, it may bring good fortune too. Tiki Totems make great avatars, so use them to reflect your hipness and elevate your cool-factor. Your Tiki Totem grants you membership into the tiki metaverse.</p>
      </div>
      <div className={classes.reasonBlock}>
        <h1 className={classes.reasonHeader}>Charitable donation!</h1>
        <p>Give yourself a pat on the back when you mint a Tiki Totem. We will be donating 7% of the proceeds to MA‘O, who does great work with the youth in Wai‘anae, Hawaii - home to the world’s largest population of native Hawaiians. <a href="https://www.maoorganicfarms.org/" target="_blank" rel="noreferrer">Click here</a> to learn more about MA‘O.</p>
        <span id="minttikitotem"></span>
      </div>
    </div>

  </main>;
}