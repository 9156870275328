import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme => ({
  roadmapSection: {
    marginTop: "4rem",
  },

  roadmap: {
    alignItems: "center",
    justifyContent: "center",
    // overflow: "hidden",
  },

  mapItem: {
    // color: "#3d3d3d",
    padding: "1rem 1rem",
    background: "rgba(250, 199, 152, 0.65)",
    font: "sans - serif",
    fontSize: "large",
    max: "360px",
    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.4)",
    border: "2px solid #844502",
    borderRadius: "3px",
    textAlign: "center",
    alignItems: "center",
    margin: "1.75rem auto",
    maxWidth: "40%",
    [theme.breakpoints.down(480)]: {
      fontSize: "medium",
      maxWidth: "100%",
    },
    [theme.breakpoints.up(480)]: {
      fontSize: "large",
    },
  },

  mapItemTitle: {
    fontSize: "x-large",
    fontWeight: 900,
    textAlign: "center",
    margin: "0px",
  },

  mapItemDetail: {
    textAlign: "center",
    padding: "0 2rem",
    marginBottom: "0px",
  },


})));
export default function Roadmap() {
  const classes = useStyles();
  return <main className={classes.roadmapSection}>

    <h1>Our project roadmap</h1>

    <div className={classes.roadmap}>
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>Project announcement<br />November 1, 2021</p>
        <p className={classes.mapItemDetail}>Announcing the launch of Tiki Totems, the first NFT project from the team at <a href="https://shaka.works" target="_blank" rel="noreferrer">Shaka Works</a></p>
      </div>
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>First Mint giveaway<br />November 1, 2021</p>
        <p className={classes.mapItemDetail}>The first minted Tiki Totem will be held in reserve and given to a lucky Tiki Totem holder after all tokens have been minted.<span id="airdrop1"></span></p>
      </div>
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>First airdrop contest<br />November 6</p>
        <p className={classes.mapItemDetail}>Our first airdrop contest ended on December 1, 2021 at 12am PST. Check our <a href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer">twitter</a> for the list winners.<span id="airdrop2"></span></p>
        {/* <p className={classes.mapItemDetail}>75 Tiki Totems will be given away to the lucky winners of our first airdrop contest - 1 Tiki Totem per twitter user. The contest starts on November 6, 2021 at 12pm PST. Check our <a href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer">twitter</a> for details.<span id="airdrop2"></span></p> */}
      </div>
      {/* <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>Second airdrop contest<br />November 15</p>
        <p className={classes.mapItemDetail}>40 Tiki Totems will be given away to the lucky winners of our second airdrop contest. The contest starts on November 15, 2021 at 12pm PST. Check our <a href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer">twitter</a> for details.</p>
      </div> */}
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>Drop date !!!<br />December 2, 2021, 12pm PST</p>
        <p className={classes.mapItemDetail}>The countdown is over! The drop begins. Mint your Tiki Totem while supplies last.</p>
      </div>
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>Airdrop to Contest Winners<br />after minting phase</p>
        <p className={classes.mapItemDetail}>We will airdrop Tiki Totems to the winners of our First Mint giveaway and our airdrop contests. Check our <a href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer">twitter</a> for details.</p>
      </div>
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>Resale marketplace listings<br />a week or so after final mint</p>
        <p className={classes.mapItemDetail}>Tiki Totems will be listed in multiple marketplaces.  Check our <a href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer">twitter</a> for details.</p>
      </div>
      <div className={classes.mapItem}>
        <p className={classes.mapItemTitle}>Special drop to current owners<br />January 1, 2022</p>
        <p className={classes.mapItemDetail}>We have a special surprise planned for those of you lucky enough to have a Tiki Totem on New Years Day!  Check our <a href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer">twitter</a> for details.</p>
      </div>
    </div>
  </main >;
}