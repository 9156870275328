import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import tikiGif from '../img/tiki-gifs.gif';
import tikiFrame from '../img/bamboo-square.png';
import blankSignSq from '../img/blank-sign-sq.png';
import blankSign from '../img/blank-sign.png';


const useStyles = makeStyles((theme => ({
  uniqueSection: {
    marginTop: "4rem",
  },
  sampleTikiTotems: {
    marginBottom: "0rem",
  },
  sampleUnique: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "2em",
    columnGap: "1em",
    width: "100%",
    margin: "0 auto",
    position: "relative",
  },
  sample: {
    width: "100%",
    height: "auto",
    position: "relative",
    display: "block",
  },
  sampleGif: {
    width: "90%",
    maxWidth: "360px",
  },
  sampleGifFrame: {
    display: "block",
    width: "99%",
    position: "absolute",
    overflow: "visible",
    top: "-12px",
  },
  sampleGifFrameImage: {
    width: "100%",
    maxWidth: "396px",
  },
  sampleDescBg: {
    display: "flex",
    width: "100%",
    maxWidth: "360px",
    background: `url(${blankSignSq})`,
    backgroundSize: "100% 100.5% ",
    backgroundRepeat: "no-repeat",
    overflow: "visible",
  },
  sampleDescText: {
    textAlign: "left",
    alignItems: "center",
    [theme.breakpoints.down(480)]: {
      padding: "2rem 1.5rem",
      fontSize: "medium",
    },
    [theme.breakpoints.up(480)]: {
      padding: "2rem 3rem",
      fontSize: "large",
    },
    [theme.breakpoints.up(820)]: {
      padding: "3rem 4rem",
      fontSize: "x-large",
    },
  },
  rarityLevels: {
    display: "flex",
    // width: "100%",
    margin: "0 auto",
    maxWidth: "870px",
    minHeight: "320px",
    background: `url(${blankSign})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    overflow: "visible",
    marginTop: "2em",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(480)]: {
      width: "100%",
    },
    [theme.breakpoints.up(820)]: {
      width: "75%",
    },
  },
  rarityContent: {
    display: "block",
    textAlign: "center",
    alignItems: "center",
    marginTop: "1.5em",
    [theme.breakpoints.down(480)]: {
      padding: "1.5em 1em 3em em",
      marginBottom: "2.5em",
    },
    [theme.breakpoints.up(480)]: {
      padding: "2em 2em 3em 2em",
      marginBottom: "1.5em",
    },
    [theme.breakpoints.up(820)]: {
      padding: "2.5em 3em 4em 3em",
      marginBottom: "1em",
    },

  },
  rarityLevelText: {
    textAlign: "left",
    margin: "0px",
    [theme.breakpoints.down(480)]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up(480)]: {
      fontSize: "large",
    },
    [theme.breakpoints.up(820)]: {
      fontSize: "x-large",
    },
  },
})));
export default function Attributes() {
  const classes = useStyles();
  return <main className={classes.uniqueSection}>

    <h1>192 unique attributes to make your Tiki Totem one of a kind</h1>

    <div className={classes.sampleUnique}>
      <div className={classes.sampleTikiTotems}>
        <div className={classes.sample}>
          <img className={classes.sampleGif} src={tikiGif} alt={"Sample Tikis"} />
          <div className={classes.sampleGifFrame}>
            <img className={classes.sampleGifFrameImage} src={tikiFrame} alt="" />
          </div>
        </div>
      </div>
      <div className={classes.sampleDescBg}>
        {/* <p className={classes.sampleDescText}>Each Tiki Totem minted has attributes with different rarity levels. We will be creating a rarity table so you can see how rare the traits are for your Tiki Totem.</p> */}
        <p className={classes.sampleDescText}>Each Tiki Totem minted has attributes with different rarity levels. To see how rare the traits are for your Tiki Totem, check out the <a href="/rarity" target="_blank" rel="noreferrer">rarity table</a>.</p>
      </div>
    </div>
    <div className={classes.rarityLevels}>
      <div className={classes.rarityContent}>
        <h1>Rarity Levels</h1>
        <p className={classes.rarityLevelText}>
          <strong>Unicorn</strong>: the only one with this trait<br />
          <strong>Mythic</strong>: less than 0.11% have this trait<br />
          <strong>Legendary</strong>: less than 2.5% have this trait<br />
          <strong>Ultra Rare</strong>: less than 5.1% have this trait<br />
          <strong>Super Rare</strong>: less than 11.2% have this trait<br />
          <strong>Rare</strong>: less than 30.5% have this trait<br />
          <strong>Common</strong>: more than 30.5% have this trait<br />
        </p>
      </div>
    </div>
  </main>;
}