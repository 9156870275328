import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme => ({
  footer: {
    display: "block",
    margin: "0 auto",
    padding: "4em 0em 1em 0em",
  },
  footerText: {
    textAlign: "center",
    alignItems: "center",
    lineHeight: "1.5em",
    [theme.breakpoints.down(480)]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up(480)]: {
      fontSize: "large",
    },
    [theme.breakpoints.up(820)]: {
      fontSize: "x-large",
    },
  },
  footerLink: {
    color: "#331B05",
    textDecoration: "none",
  },
  icons: {
    color: "#331B05",
    fontSize: "4rem",
  },
})));


export default function Footer() {
  const copyrightYear = new Date().getFullYear();
  const classes = useStyles();
  return <main>
    <div className={classes.footer}>
      {/* <p><a className={classes.footerLink} href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer"><Twitter className={classes.icons} /></a></p> */}
      <p>
        <a className={classes.footerLink} href="https://twitter.com/TikitotemsNFT" target="_blank" rel="noreferrer"><span className={`${classes.icons} iconify`} data-icon="akar-icons:twitter-fill"></span></a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a className={classes.footerLink} href="https://discord.gg/MzPfNCa6mx" target="_blank" rel="noreferrer"><span className={`${classes.icons} iconify`} data-icon="akar-icons:discord-fill"></span></a>
      </p>
      <p className={classes.footerText}>
        Brought to you by the team at <a href="https://shaka.works" target="_blank" rel="noreferrer">Shaka Works</a><br />
        &copy; {copyrightYear} All rights reserved<br />
        GPG fingerprint: <a className={classes.footerLink} href="https://keys.openpgp.org/vks/v1/by-fingerprint/5CEB2986E7B0547A852F820E1BC29F71666EC6D9" target="_blank" rel="noreferrer">5CEB2986E7B0547A852F820E1BC29F71666EC6D9</a><br />
        <a className={classes.footerLink} href="./shakaworks-sig.txt" target="_blank" rel="noreferrer">GPG signature</a>
      </p>

    </div>
  </main>;
}
